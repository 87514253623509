import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import VerticalNavBar from '../components/Navbar/VerticalNavBar'
import QuestionnaireResult from '../components/Stepper/QuestionnaireResult'
import ResultTable from '../components/Table/ResultTable'

const ResultPage = () => {


    const questionnaireResponseExample = {
        "¿Tienes ejecutivos de alto nivel que quieres retener?": "Sí",
        "¿Notas que está sucediendo alguna o varias de estas manifestaciones? Marca todas las que apliquen": ["Ansiedad", "Desgaste", "Cansancio"],
        "Está sucediendo con:": "Una persona",
        "¿Está pasando esto en tu empresa?": ["Quejas de equipos hacia su líder", "Disminución de la productividad"],
        "¿Se están yendo tus talentos?":"Son personas muy valiosas",
        "¿Donde?": "Varias áreas de tu empresa",
        "¿Algo más que quieres agregar?": "Necesitamos mejorar la comunicación interna en el equipo de ventas.",
      };
      

  return (<>
  <Navbar/>
      <div className='flex'>
        <div className='aboslute w-[200px] h-[100vh]'>
            <VerticalNavBar/>
        </div>
        <div className='flex flex-grow-1 p-5'>
            <QuestionnaireResult questionnaireResponse={questionnaireResponseExample} />
        </div>
        <div>

        </div>
      </div>

  <Footer/>
  </>

  )
}

export default ResultPage