import React, { useState } from 'react';
import { TextField, Button, Container, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const UserInfoForm = ({ user, setUser }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    nombreApellido: '',
    email: '',
    whatsapp: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setUser(formData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      nombreApellido: '',
      email: '',
      whatsapp: '',
      company: '',
    });
  };

  return (
    <Container maxWidth="xs">

        <div className="flex justify-center">
            <div className="img-container h-36 overflow-hidden">
                <img src={require('../..//assets/icons/Logos/Logo-removebg.png')} className="w-52 object-cover" alt="Flowbite Logo" />
            </div>
        </div>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          className={classes.textField}
          label="Nombre y Apellido"
          variant="outlined"
          name="nombreApellido"
          value={formData.nombreApellido}
          onChange={handleChange}
        />
        <TextField
          className={classes.textField}
          label="Email"
          variant="outlined"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          className={classes.textField}
          label="WhatsApp"
          variant="outlined"
          name="whatsapp"
          value={formData.whatsapp}
          onChange={handleChange}
        />
        <TextField
          className={classes.textField}
          label="Empresa/Compañia"
          variant="outlined"
          name="company"
          value={formData.company}
          onChange={handleChange}
        />
      </form>
    </Container>
  );
};

export default UserInfoForm;
