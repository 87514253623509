export const Options = [
  {
    key: 'branding',
    title: 'Branding y liderazgo para CEO´s y Top Execs.',
    description: 'Nada más cautivador en los negocios que una personalidad natural bien entrenada, tanto para tomar decisiones con la cabeza fría como para liderar con certeza en cualquier situación.',
    imgUrl: '../../assets/images/Jahn-b-0.jpg'
  },
  {
    key: 'biohacking',
    title: 'Biohacking',
    description: 'Al integrar a la vida diaria sencillas técnicas wellness y cambio de hábitos, las personas elevan su productividad casi 50%, se mantienen saludables, con altos niveles de energía y mayor resiliencia ante el estrés.',
    imgUrl: '../../assets/images/Jahn-b-1.jpg'
  },
  {
    key: 'xplendor',
    title: 'Xplendor',
    description: 'Quien conoce su propósito, le apasiona su trabajo y pule sus fortalezas irradia felicidad que se refleja en su desempeño laboral. Nuestro trabajo es ayudar a encontrar la plenitud.',
    imgUrl: '../../assets/images/Jahn-b-2.jpg'
  },
  {
    key: 'mindHacking',
    title: 'Mind hacking',
    description: 'Hacer que las cosas sucedan tiene sus secretos: aprender a planear, manejar emociones, comunicar con efectividad y decidir sin titubear. ¿La estrategia? Dominar la mente.',
    imgUrl: '../../assets/images/Jahn-b-3.jpg'
  },
  {
    key: 'teamCoaching',
    title: 'Coaching de equipo',
    description: 'El alto desempeño va de la mano con los ambientes laborales sanos, razón suficiente para propiciar relaciones libres de pugnas y espacios abiertos a la expresión de ideas.',
    imgUrl: '../../assets/images/Jahn-b-4.jpg'
  },
  {
    key: 'certifications',
    title: 'Certificaciones "Leader Coach" y "Mentor Coach"',
    description: '¿Buscas que los líderes de tu organización orienten e inspiren con certeza a sus equipos? Con las herramientas precisas se obtienen resultados impresionantes: mayor autoconfianza, independencia y proactividad, cualidades indispensables para mejorar el desempeño de todos.',
    imgUrl: '../../assets/images/Jahn-b-5.jpg'
  }
];


export const Options_Group = [
  {
    key: 'boostProductivity',
    title: 'Boost Productivity',
    description: 'Offer individual and group solutions that quickly resolve obstacles reducing human capital productivity. The result? 63% less turnover, 70% increased efficiency.',
    imgUrl: '../../assets/images/Coaching.webp'
  },
  {
    key: 'promoteTrust',
    title: 'Promote Trust',
    description: 'Restore self-control to employees, instill confidence in clients, and enhance your competitive standing.',
    imgUrl: '../../assets/images/empresa-wellness-mujer-relajada.webp'
  },
  {
    key: 'exceedSalesGoals',
    title: 'Exceed Sales Goals',
    description: 'Immediate impact on company revenue thanks to the transformation of standout personnel.',
    imgUrl: '../../assets/images/Business-Plan.webp'
  }
];

