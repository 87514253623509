import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { ReviewData } from './ReviewData';
import ReviewCards from '../Cards/ReviewCards';
import { useTranslation } from 'react-i18next';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const ReviewsSliders = () => {

  const { t } = useTranslation('reviews');

  const breakpoints = {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 10,
      centeredSlides:true,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 1,
      spaceBetween: 10,
      centeredSlides:true,
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
      centeredSlides:false,
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 20,
      centeredSlides:false,
    },
    1280: {
      slidesPerView: 3,
      spaceBetween: 20,
      centeredSlides:false,
    },
  };

  return (
    <>
    <Swiper
      breakpoints={breakpoints}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      className="mySwiper h-[800px] md:h-[700px] w-full md:w-11/12"
    >
        {ReviewData.map((item,index)=>{
            return <SwiperSlide key={index}>
                <ReviewCards image={item.imgUrl} review={t(`review${index+1}.review`)} name={t(`review${index+1}.user`)}company={t(`review${index+1}.company`)} color={item.color} height={item.height}/>
            </SwiperSlide>
        })}
    </Swiper>
  </>
  )
}

export default ReviewsSliders