import React, { useState } from 'react';
import {
  Container,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap:'2rem'
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const ContentForm = ({questionsResponse,setQuestionsResponse}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    retentionExecutives: '',
    manifestations: [],
    happeningWith: '',
    companyIssues: [],
    additionalComments: '',
    where: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    if (type === 'checkbox') {
      const isChecked = checked;
      let updatedArray;
      if (name === 'manifestations') {
        updatedArray = isChecked
          ? [...formData.manifestations, value]
          : formData.manifestations.filter((item) => item !== value);
  
        setFormData((prevFormData) => ({
          ...prevFormData,
          manifestations: updatedArray,
        }));
        setQuestionsResponse(formData);
      } else if (name === 'companyIssues') {
        updatedArray = isChecked
          ? [...formData.companyIssues, value]
          : formData.companyIssues.filter((item) => item !== value);
  
        setFormData((prevFormData) => ({
          ...prevFormData,
          companyIssues: updatedArray,
        }));
        setQuestionsResponse(formData);
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setQuestionsResponse(formData);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      retentionExecutives: '',
      manifestations: [],
      happeningWith: '',
      companyIssues: [],
      additionalComments: '',
      where: '',
    });
  };

  return (
    <Container maxWidth="md">
      <h2 className='py-5 text-center font-bold'>Cómo saber si Jahn Institute me puede ayudar?</h2>
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormControl component="fieldset">
          <FormLabel component="legend" className='my-2'>
            <Typography variant='h6' className='text-black font-bold'>
              ¿Tienes ejecutivos de alto nivel que quieres retener?
            </Typography>
          </FormLabel>
          <TextField
            variant="outlined"
            name="retentionExecutives"
            value={formData.retentionExecutives}
            onChange={handleChange}
          />
        </FormControl>
  
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Typography variant='h6' className='text-black font-bold'>
              ¿Notas que está sucediendo alguna o varias de estas manifestaciones? Marca todas las que apliquen
            </Typography>
          </FormLabel>
          <FormGroup>
            {['Ansiedad', 'Acelere', 'Stress', 'Desgaste', 'Mal humor', 'Cansancio', 'Desánimo'].map((manifestation) => (
              <FormControlLabel
                key={manifestation}
                control={
                  <Checkbox
                    value={manifestation}
                    name='manifestations'
                    checked={formData.manifestations.includes(manifestation)}
                    onChange={handleChange}
                  />
                }
                label={manifestation}
              />
            ))}
            <FormControlLabel
              control={
                <Checkbox
                  value="otros"
                  name="manifestations"
                  checked={formData.manifestations.includes('otros')}
                  onChange={handleChange}
                />
              }
              label="Otros"
            />
            {formData.manifestations.includes('otros') && (
              <TextField
                name="otros"
                label="Especificar Otros"
                variant="outlined"
                value={formData.otros}
                onChange={handleChange}
              />
            )}
          </FormGroup>
        </FormControl>
  
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Typography variant='h6' className='text-black font-bold'>
              Está sucediendo con:
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-label="happeningWithOneOrSeveral"
            name="happeningWithOneOrSeveral"
            value={formData.happeningWithOneOrSeveral}
            onChange={handleChange}
          >
            <FormControlLabel value="Una persona" control={<Radio />} label="Una persona" />
            <FormControlLabel value="Varias personas" control={<Radio />} label="Varias personas" />
          </RadioGroup>
        </FormControl>
  
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Typography variant='h6' className='text-black font-bold'>
              ¿Está pasando esto en tu empresa? Marca todo lo que aplique
            </Typography>
          </FormLabel>
          <FormGroup>
            {[
              'Quejas de equipos hacia su líder',
              'Disminución de la productividad',
              'Disminución de resultados',
              'Clima laboral pesado',
            ].map((issue) => (
              <FormControlLabel
                key={issue}
                control={
                  <Checkbox
                    value={issue}
                    name='companyIssues'
                    checked={formData.companyIssues.includes(issue)}
                    onChange={handleChange}
                  />
                }
                label={issue}
              />
            ))}
          </FormGroup>
        </FormControl>
        
        <TextField
          className={classes.textField}
          label="En que Area de la Empresa?"
          variant="outlined"
          name="where"
          value={formData.where}
          onChange={handleChange}
        />
  
        <TextField
          className={classes.textField}
          label="¿Algo más que quieres agregar?"
          variant="outlined"
          name="additionalComments"
          value={formData.additionalComments}
          onChange={handleChange}
          multiline
          minRows={4}
        />
  
      </form>
    </Container>
  );
  
};

export default ContentForm;
