import logo from './logo.svg';
import './App.css';
import {Route, Routes } from "react-router-dom";
import FrontPage from './pages/FrontPage';
import QuestionnairePage from './pages/QuestionnairePage';
import ResultPage from './pages/ResultPage';
import TablePage from './pages/TablePage';
import { I18nextProvider } from 'react-i18next';
import i18next from './utils/i18n';

function App() {
  
  return (<>
       <I18nextProvider i18n={i18next}>
          <Routes>
            <Route path="/"  element={<FrontPage/>} />
            <Route path="/questionnaire" element={<QuestionnairePage/>} />
            <Route path="/cuestionario" element={<TablePage/>} />
            <Route path="/cuestionario/:id" element={<ResultPage/>} />
          </Routes>
        </I18nextProvider>
      </>
  );
}
export default App;
