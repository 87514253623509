import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GoogleIcon from '@mui/icons-material/Google';

const Footer = () => {
  return (
    <div className='flex flex-col justify-center gap-5 p-10 bg-slate-900 w-full text-center'>
        <div className="imagae-container flex justify-center">
            <img src={require('../../assets/icons/Logos/Logo-white-jh.png')} alt="" />
        </div>
        <div className="text-container flex flex-col gap-5 text-center justify-center  text-white text-bold">
                <h3>Jahn Institute. Transformando vidas, impulsando organizaciones</h3>
                <h4 className='text-md text-xl'>© 2024 JAHN INSTITUTE</h4>
        </div>
        <div className="social-contianer flex justify-center gap-5 text-white">
            <FacebookIcon/>
            <XIcon/>
            <GoogleIcon/>
            <InstagramIcon/>
            <LinkedInIcon/>
        </div>

    </div>
  )
}

export default Footer