import React from 'react';
import './wavesStyle.css';
import WaveIcon from './WaveIcon';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const WavesForm = () => {
  const { t } = useTranslation('wavesForm');
  const isMobile = window.innerWidth < 768; 

  return (
    <>
      <div className="header">
        <div className="flex flex-wrap justify-around items-center w-full">
          <div className="text-container flex flex-col gap-10 w-full md:w-3/4 p-5">
            <h2>{t('headerTitle')}</h2>
            <h6 className='text-center' dangerouslySetInnerHTML={{ __html: t('headerSubtitle') }}></h6>
          </div>
          <div className='form-container px-2 md:px-10 flex flex-col gap-5'>
            <div className='flex flex-wrap justify-center items-center gap-3 w-full'>
              <TextField
                id="outlined-basic"
                label={t('nameLabel')}
                variant="outlined"
                sx={{ m: 1, width: isMobile ? '100%' : '40ch' }} // Set width to 100% on mobile, otherwise 40ch
              />
              <TextField
                id="outlined-basic"
                label={t('emailLabel')}
                variant="outlined"
                sx={{ m: 1, width: isMobile ? '100%' : '40ch' }} // Set width to 100% on mobile, otherwise 40ch
              />
            </div>
            <TextField
              id="outlined-multiline-static"
              label={t('messageLabel')}
              multiline
              rows={5}
              sx={{ m: 1, width: isMobile ? '100%' : '82ch' }} // Set width to 100% on mobile, otherwise 82ch
              defaultValue={t('defaultMessage')}
            />
          </div>
          <div className="w-full py-5">
            <button className='button-23'>{t('contactButton')}</button>
          </div>
        </div>
        <div>
          <WaveIcon />
        </div>
      </div>
    </>
  );
};

export default WavesForm;
