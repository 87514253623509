import React from 'react'
import './BoxType1Style.css'

const BoxType2 = ({title, imgSrc, description }) => {
  return (
    <div className="flex flex-col-reverse lg:flex-row justify-center items-center relative  w-[300px] lg:w-[1000px] xl:w-[1200px]">
        <div className='flex justify-center border-4 border-[#C00000] rounded-xl py-5 px-5 xl:pr-[7rem] relative bg-slate-100 w-[300px] lg:w-[800px] xl:w-[950px] left-[0] lg:left-[25px] xl:left-[120px] top-[20px]'>
          <p className='' dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <div className="hexagon z-10">
          <span className='flex flex-col'>
            <img src={imgSrc ? imgSrc : "../assets/icons/discovery-icon.png"} alt="" />
            <p className='font-bold'>{title}</p>
          </span>
        </div>
    </div>

  )
}

export default BoxType2