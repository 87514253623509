import React,{useState} from 'react'
import { useTranslation } from 'react-i18next';
import { Options } from './Data';
import './SelectBoxStyle.css';


const SelectBox = () => {
    const [currentOption,setCurrentOption] = useState(Options[0]);
    const { t } = useTranslation('options');

    const handleOption=(index)=>{
        setCurrentOption(Options[index])
    }

  return (
  <div className='flex flex-wrap h-auto lg:h-[600px] w-[100%] lg:w-[1000px] shadow-lg bg-white'>
    <div className="flex flex-col gap-3 justify-start menu bg-slate-700 w-full lg:w-[280px] min-w-[300px]  p-5">
        <h6 className='text-white text-left text-2xl font-bold decoration-[3px]'>{t('menuTitle')}</h6>
        {Options.map((item,index)=>{
            return <h6 
                key={index}
                className={`menu-item text-white text-left font-bold decoration-[3px]  decoration-[#CC01D0] hover:underline underline-offset-4 cursor-pointer ${index < 4 ? 'pl-5 text-lg':'text-2xl' } ${item.title == currentOption.title ? 'underline !decoration-[#B1D11E] active-option':''}`}
                onClick={()=>handleOption(index)}
            > {t(`${item.key}.title`)}</h6>
        })}
    </div>
    <div className='content-container px-0 py-5 lg:p-10 flex flex-col gap-5 flex-grow w-full lg:w-[700px]'>
        <h4 className='title text-left font-bold'>
            {currentOption &&  t(`${currentOption.key}.title`)}
        </h4>
        <p className='description text-justify'>
            {currentOption && t(`${currentOption.key}.description`)}
        </p>
        <div className="image-container w-full md:w-[600px] h-[450px]">
            <img className='object-cover w-full h-full' src={currentOption.imgUrl} alt="" />
        </div>
    </div>

    </div>
  )
}

export default SelectBox