import React,{useState} from 'react'
import { Options_Group } from './Data';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SelectBoxType3 = () => {
const { t } = useTranslation('optionsGroup');

const navigate = useNavigate();

const handleTestClick = () => {
  navigate('/questionnaire');
};

  return (
    <div className="flex flex-col gap-10">
        {Options_Group.map((item, index) => (
            <div
            key={index}
            className={`flex gap-10 w-full justify-around ${
                index % 2 !== 0 ? 'lg:flex-row-reverse' : '' // Flip odd items
            }`}
            >
                <div className="flex flex-col gap-3 text-left w-1/2">
                    <h5 className="font-bold text-3xl">{t(`${item.key}.title`)}</h5>
                    <p>{t(`${item.key}.description`)}</p>
                    <div className="hidden lg:flex w-120 py-10 gap-5">
                    <button className="button-23">{t('buttons.learnMore')}</button>
                    <Button variant="text" onClick={handleTestClick}>
                        {t('buttons.needMentorship')}
                    </Button>
                    </div>
                </div>
                <div className="flex flex-grow justify-center items-center">
                    <div className="image-container w-full h-[300px] max-w-[600px]">
                        <img className="object-cover w-full h-full" src={item.imgUrl} alt="" />
                    </div>
                </div>
            </div>
        ))}
    </div>
  )
}

export default SelectBoxType3