import React from 'react'
import './BoxType1Style.css'

const BoxType1 = ({title, imgSrc, description }) => {
    return (
      <div className="flex flex-col lg:flex-row justify-center items-center relative w-[300px] lg:w-[1000px] xl:w-[1250px]">
        <div className="hexagon z-10">
          <span className='flex flex-col'>
            <img src={imgSrc ? imgSrc : "../assets/icons/discovery-icon.png"} alt="" />
            <p className='font-bold'>{title}</p>
        </span>
          
        </div>
        <div className='flex justify-center border-4 border-[#C00000] rounded-xl py-5 relative bg-slate-100 w-[300px] lg:w-[800px] xl:w-[950px] p-5 xl:pl-[7rem]  left-[0]  lg:left-[-25px] xl:left-[-110px] top-[20px]'>
          <p className='' dangerouslySetInnerHTML={{ __html: description }} /> {/* Removed double curly braces */}
        </div>
      </div>
    );
  }

export default BoxType1