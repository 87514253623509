export const ReviewData=[
    {
        imgUrl:'../../assets/images/companies/Dow.png',
        user:'Filip Tauson',
        company:'CEO Dow Company, México and Latam',
        review:'Direction, purpose and cultural immersion are the key and skills I learn from Jahn Institute. Mercedes Jahn is the perfect starting point of any senior leader who wants to make a quick impact on people and their contribution to the organization as a foreign executive in an overseas work environment',
        color:'rose',
        height:'h-[30px]'
    },
    {
        imgUrl:'../../assets/images/companies/tascomm_logo.png',
        user:'Alejandro Salum',
        company:'CEO Tascomm México',
        review:'He tenido el placer de trabajar con la Dra. Jahn en varios proyectos. Entrega resultados tangibles a corto plazo. Ha sido mi coach personal y profesional por muchos años y he trabajado con ella desde los aspectos más complejos de mi desempeño profesional, hasta los más significativos de mi vida personal.',
        color:'green',
        height:'h-[80px]'
    },
    {
        imgUrl:'../../assets/images/companies/Arcacontinental.png',
        user:'Hernán N.',
        company:'Director Comercial Arca Continental  Perú',
        review:'Estoy muy satisfecho con la metodología de coaching de Mercedes Jahn. Abierta, empática y con gran capacidad de escucha, nuestras conversaciones siempre me dejaron una reflexión importante que me llevó al autodescubrimiento, a tener claro mi propósito y a regresar con fuerza al spotlight para llevar nuestras ventas a tope',
        color:'rose',
        height:'h-[60px]'
    },
]