const frontPageText = {
    en: {
      title: 'Detonate and take advantage of the strengths of human capital',
      description1: "As a leader in the Human Resources area, being the good or bad guy in the movie depends on your competencies to ensure that the CEOs, high-level executives, and their teams accelerate the company's objectives and position it at the top of the market.",
      description2: "At Jahn Institute, we understand the fundamental nature of your role in achieving this; therefore, we facilitate your work by offering Corporate Coaching, as well as effective Life-Work Development and Wellness Strategies designed for these key actors to learn to take control of their emotions, decisions, and actions.",
      button: "I need your solutions",
      coachingTitle: 'The ROI of certified Executive Coaching',
      coaching1: 'Profitability against the competition',
      coaching2: 'Investment recovery',
      coaching3: 'Productivity',
      coaching4: 'Work-life balance',
      solutionsTitle: 'Solutions that transform lives in less than six months',
      mentoring: 'Mentoring/1:1 Coaching | Team Coaching | Advanced Certifications',
      growTitle: 'Everyone grows, you grow',
      growDescription: 'Feeling overwhelmed by all the existing Executive Coaching options to boost your organization\'s top talent according to their objectives is normal. At Jahn Institute, we help you make decisions with confidence so that you are sure to select the right solution, based on the situation and timing, future results, and expected ROI.',
      successTitle: 'Keys that determine your success',
      successDescription: 'Our Progressive Success 360 methodology has provided successful results, proven by dozens of executives and their corporations in multiple countries.',
      radiographyTitle: 'Radiography',
      radiographyDescription: 'The personalized analysis phase to understand what your talent and the organization want and need',
      discoveryTitle: 'Discovery',
      discoveryDescription: 'The moment of discovery of professional/corporate requirements, the specific solution, and the vigilant sponsor of the progress process',
      headwayTitle: 'Headway',
      headwayDescription: 'The work stage through live online sessions (60-90 mins) to unlock objectives and see evident progress in less than six months',
      goalTitle: 'Goal',
      goalDescription: 'The program closing to verify the goals achieved in the presence of the collaborator, the sponsor, and the HR representative',
      quote: '"It\'s not what I do, but what people achieve when they work with me"',
      bioTitle: 'Mercedes Jahn, Corporate Plenitude Mentor',
      bioSubtitle: 'Helping people transform to achieve success with plenitude is my passion.',
      bioDescription: '30 years ago, I was fortunate to come to Mexico to introduce Coaching. I belong to the 2% of Senior Master Coaches worldwide and through Jahn Institute, in partnership with WWB, LLC, I offer specialized solutions with the support of my team of expert consultants to strengthen large organizations.',
      bioList: [
        'Senior Integral Executive Coach Level C, consultant, mentor, and biohacker',
        'Board member, writer, speaker, and researcher',
        'Specialist in cognitive neurosciences with degrees in International Business and Languages (specialty in translation)',
        'Master in Emotional-Cognitive-Behavioral Psychotherapy and Business Management',
        'Doctor in Psychology'
      ],
      testimonials: 'In the words of our clients',
    },
    es: {
      title: 'Detona y aprovecha las fortalezas del capital humano',
      description1: "Como líder del área de Recursos Humanos, ser el bueno o el malo de la película depende de tus competencias para garantizar que los CEO's, ejecutivos de alto nivel y sus equipos aceleren los objetivos de la compañía y la posicionen en primer lugar del mercado.",
      description2: "En Jahn Institute entendemos lo fundamental de tu función para conseguirlo; por eso, facilitamos tu labor ofreciendo Coaching Corporativo, al igual que efectivas Estrategias de Desarrollo y Bienestar vida-trabajo diseñadas para que estos actores clave aprendan a tomar control de sus emociones, decisiones y acciones.",
      button: "Necesito sus soluciones",
      coachingTitle: 'El ROI del Coaching Ejecutivo certificado',
      coaching1: 'Rentabilidad frente a la competencia',
      coaching2: 'Recuperación de la inversión',
      coaching3: 'Productividad',
      coaching4: 'Equilibrio vida/trabajo',
      solutionsTitle: 'Soluciones que transforman vidas en menos de seis meses',
      mentoring: 'Mentoría/Coaching 1:1 | Coaching de equipo | Certificaciones avanzadas',
      growTitle: 'Crecen todos, creces tú',
      growDescription: 'Sentir agobio frente a todas las opciones de Coaching Ejecutivo existentes para impulsar al top talent de tu organización, según sus objetivos, es normal. En Jahn Institute te ayudamos a tomar decisiones con seguridad para que tengas la certeza de seleccionar la solución adecuada, de acuerdo con la situación y el momento, los resultados futuros y el ROI esperado.',
      successTitle: 'Claves que determinan tu éxito',
      successDescription: 'Nuestra metodología Progressive Success 360 ha brindado resultados exitosos, comprobados por decenas de ejecutivas y ejecutivos, así como por sus corporaciones en múltiples países.',
      radiographyTitle: 'Radiography',
      radiographyDescription: 'La fase de análisis personalizado para entender qué quiere y qué necesita tu talento y la organización',
      discoveryTitle: 'Discovery',
      discoveryDescription: 'El momento de hallazgo de los requerimientos profesionales/corporativos, la solución específica y el sponsor vigilante del proceso de avance',
      headwayTitle: 'Headway',
      headwayDescription: 'La etapa de trabajo mediante sesiones online en vivo (60-90 mins) para desbloquear objetivos y ver progreso evidente en menos de seis meses',
      goalTitle: 'Goal',
      goalDescription: 'El cierre del programa para comprobar las metas alcanzadas en presencia del colaborador, el sponsor y el representante de Recursos Humanos',
      quote: '"No es lo que hago, sino lo que las personas logran cuando trabajan conmigo"',
      bioTitle: 'Mercedes Jahn, Mentora de Plenitud Corporativa',
      bioSubtitle: 'Ayudar a la gente a transformarse para alcanzar el éxito con plenitud es mi pasión.',
      bioDescription: 'Hace 30 años tuve la fortuna de llegar a México para dar a conocer el Coaching. Pertenezco al 2% de los Senior Master Coaches a nivel mundial y a través de Jahn Institute, en sociedad con WWB, LLC, ofrezco soluciones especializadas con el apoyo de mi equipo de consultores expertos para fortalecer a grandes organizaciones.',
      bioList: [
        'Coach Ejecutivo Integral Senior Nivel C, consultora, mentora y biohacker',
        'Miembro de consejo, escritora, conferencista e investigadora',
        'Especialista en neurociencias cognitivas con licenciaturas en Negocios Internacionales y en Idiomas (especialidad traducción)',
        'Maestra en Psicoterapia Emocional-Cognitivo-Conductual y en Gestión de Empresas',
        'Doctora en Psicología'
      ],
      testimonials: 'En palabras de nuestros clientes',
    }
  };
  
  export default frontPageText;
  