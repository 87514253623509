import { publicRequest } from "../requestMethods/requestMethods";


export const getAllQuestionnaireResponses = async () => {
    try {
      const res = await publicRequest.get(`/question_responses`);
      return res;
    } catch (error) {
      console.error('Error fetching questionnaire responses:', error);
      throw new Error('Error fetching questionnaire responses');
    }
  };

  export const getQuestionnaireResponseById = async (id) => {
    try {
      const response = await publicRequest.get(`/question_responses/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching questionnaire response by ID:', error);
      throw new Error('Error fetching questionnaire response by ID');
    }
  };