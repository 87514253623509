import React from 'react'
import { AdminMenuData } from './AdminMenuData'
import { Link } from 'react-router-dom';

const VerticalNavBar = () => {
    return (
      <div className='w-full bg-slate-500 '>
        {AdminMenuData.map((item, index) => (
          <Link to={item.link} key={item.name || index}>
            <div key={index} className="flex gap-10 justify-center items-center">
              {item.icon}
            </div>
            </Link>
        ))}
      </div>
    );
  };
  

export default VerticalNavBar