import React from 'react'
import Questionnaire from '../components/Stepper/Questionnaire'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'

const QuestionnairePage = () => {
  return (<>
      <Navbar/>
      <Questionnaire/>
      <Footer/>
  </>
  )
}

export default QuestionnairePage