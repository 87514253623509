import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GoogleIcon from '@mui/icons-material/Google';



const CompleteForm = () => {

  const sendMessageToWhatsApp = () => {
    const phoneNumber = '+525532054072'; // Replace this with the recipient's phone number
    const message = 'Hello from my website!'; // Replace this with your message
    
    const formattedMessage = encodeURIComponent(message);
    const url = `https://wa.me/${phoneNumber}?text=${formattedMessage}`;
  
    window.open(url, '_blank');
  };


  return (
    <div className='flex flex-col justify-center items-center gap-5'>
        <div className="image-container w-48 ">
            <img className='object-cover h-full w-full' src={require("../../assets/icons/Logos/Logo.jpeg")} alt="" />
        </div>
        <div className="flex flex-col gap-3 text-center">
             <h4>Has completado de forma exitosa el Test</h4>
             <h5>Te estaremos contactando en brevedad</h5>
             <h5>contacto@jahninstitute.com</h5>
             <button onClick={sendMessageToWhatsApp}>sendMessageToWhatsApp</button>
        </div>
        <div className="social-contianer flex justify-center gap-5">
            <FacebookIcon/>
            <XIcon/>
            <GoogleIcon/>
            <InstagramIcon/>
            <LinkedInIcon/>
        </div>
       

    </div>
  )
}

export default CompleteForm