import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SendIcon from '@mui/icons-material/Send';

export const AdminMenuData=[
    {
        title:'Home',
        icon:<HomeIcon/>,
        link:'/'
    },
    {
        title:'Lista',
        icon:<ListAltIcon/>,
        link:'/cuestionario'
    }
]