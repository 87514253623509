import React,{ useState, useEffect } from 'react'
import Waves from '../components/Waves/Waves'
import Navbar from '../components/Navbar/Navbar'
import ReviewsSliders from '../components/Slider/ReviewsSliders'
import SelectBox from '../components/SelectBox/SelectBox'
import Footer from '../components/Footer/Footer'
import WavesForm from '../components/Waves/WavesForm'
import './FrontPageStyle.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GoogleIcon from '@mui/icons-material/Google';
import SelectBoxType2 from '../components/SelectBox/SelectBoxType2'
import BoxType1 from '../components/Box/BoxType1'
import BoxType2 from '../components/Box/BoxType2'
import SelectBoxType3 from '../components/SelectBox/SelectBoxType3'
import AOS from "aos";
import "aos/dist/aos.css";
import frontPageText from '../utils/Translations/frontPageText'
import { useTranslation } from 'react-i18next'


const FrontPage = () => {

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
const [language, setLanguage] = useState('es');
const [text,setText] = useState(frontPageText[language]);

useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };
  window.addEventListener('resize', handleResize);
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

const { t } = useTranslation('frontPage');
console.log(t('home')); // Should log 'Home' in English and 'Inicio' in Spanish


useEffect(()=>{
  console.log(language);
  console.log(text.button);
  setText(frontPageText[language]);
},[language])

  return (<>
      <Navbar setLanguage={setLanguage} />
      <Waves/>
      <section  id='doleres' className='my-10'>
        <div className='flex flex-wrap justify-center items-center w-full'> 
          <div  data-aos="zoom-in-right" data-aos-duration="1000"  className="text-container flex flex-col gap-5 p-5 w-full md:w-1/2">
            <h2 className='text-left uppercase font-bold'>{t('wavesTitle')}</h2>
            <div className="decription text-justify">
            <p className='text-black' dangerouslySetInnerHTML={{ __html: t('wavesParagraph1', {
              leaderRole: `<b>${t('leaderRole')}</b>`,
              CEOs: `<b>CEO's, ejecutivos de alto nivel y sus equipos</b>`,
              JahnInstitute: `<b>Jahn Institute</b>`,
            }) }}></p>
            <p className='text-black' dangerouslySetInnerHTML={{ __html: t('wavesParagraph2', {
              leaderRole: `<b>${t('leaderRole')}</b>`,
              CEOs: `<b>CEO's, ejecutivos de alto nivel y sus equipos</b>`,
              JahnInstitute: `<b>Jahn Institute</b>`,
              Coaching: `<b>${t('ecoCoaching')}</b>`,
              DevelopmentStrategies: `<b>${t('developmentStrategies')}</b>`
            }) }}></p>
            </div>
          </div>
          <div className="content-container flex flex-col gap-3 justify-center items-center w-full md:w-1/2">
            <div   data-aos="zoom-in-left" data-aos-duration="1000" className="image-container w-[350px] h-[500px] rounded-full overflow-hidden border-8 border-[#C00000]">
              <img className='object-cover w-full h-full' src={require("../assets/images/Jahn-07.jpg")} alt="" />
            </div>
            <button className='button-23 w-fit'>{t('buttonSolution')}</button>
          </div>
        </div>
      </section>
      <section id='coaching' className='bg-slate-700 py-5'>
        <div className="title px-2 md:px-[25%] py-5">
          <h2 className='uppercase text-center font-bold text-white'>{t('coachingROI')}</h2>
        </div>
        <div className="content-container flex flex-wrap justify-around">
          <div className='icon-box flex flex-col gap-5 justify-center items-center w-64'>
            <img src={require("../assets/icons/GN.png")} width="120" alt="" />
            <h4 className='text-white font-bold'>53%</h4>
            <p className='text-white text-center'>{t('returnOnInvestment')}</p>
          </div>
          <div className='icon-box flex flex-col gap-5 justify-center items-center w-64'>
            <img src={require("../assets/icons/GN 2.png")} width="120" alt="" />
            <h4 className='text-white font-bold'>7X</h4>
            <p className='text-white text-center'>{t('investmentRecovery')}</p>
          </div>
          <div className='icon-box flex flex-col gap-5 justify-center items-center w-64'>
            <img src={require("../assets/icons/GN 3.png")} width="120" alt="" />
            <h4 className='text-white font-bold'>70%+</h4>
            <p className='text-white text-center'>{t('productivity')}</p>
          </div>
          <div className='icon-box flex flex-col gap-5 justify-center items-center w-64'>
            <img src={require("../assets/icons/balance.png")} width="120" alt="" />
            <h4 className='text-white font-bold'>61%+</h4>
            <p className='text-white text-center'>{t('workLifeBalance')}</p>
          </div>
        </div>
      </section>
      <section className='relative mx-2 md:mx-10 border-l-8 border-[#B1D11E] mt-10 mb-5 lg:mb-56'>
        <div className='w-full lg:w-1/2 '>
          <h2 className='text-left'>{t('solutionsTitle')}</h2>
          <h6 className='text-lg pl-3 font-bold'>{t('solutionsSubtitle')}</h6>
        </div>
        <div className="image-container hidden lg:block h-[600px] ">
          <img  className='object-cover w-full h-full' src={require('../assets/images/Jahn-b-5.jpg')} alt="" />
        </div>
        <div className='relative lg:absolute top-[20%] left-0 lg:left-[1%] xl:left-[18%]'>
        <SelectBox/>
        </div>
      </section>
      <section className='relative mx-2 md:mx-10 border-r-8 border-[#CC01D0] mb-24'>
        <div className="py-5 flex flex-col gap-5 w-full pr-10">
        <h2 className='text-left'>{t('growSection.title')}</h2>
        <p className='text-justify' dangerouslySetInnerHTML={{ __html: t('growSection.description') }} />
        </div>
        {isMobile && <SelectBoxType2 />}
        {!isMobile && <SelectBoxType3 />}
      </section>
      <section id="success-section" className='relative mx-0 md:mx-10 border-l-8 border-[#B1D11E] mb-0 relative'>
      <div className='absolute left-0 h-full'>
        <img className="triangle-shape-right" src={require("../assets/images/City.jpg")} alt="Triangle shape"/>
      </div>
      <div className='absolute right-0 h-full'>
        <img className="triangle-shape" src={require("../assets/images/City.jpg")} alt="Triangle-shape-right"/>
      </div>
      <div className='w-full md:w-3/4 flex flex-col gap-5 pr-5 md:pr-0 pt-24 relative'>
        <h2 className='text-left'>{t('successSection.title')}</h2>
        <p className='text-justify'>{t('successSection.methodologyDescription')}</p>
      </div>
      <div className="content-container flex flex-col gap-24 justify-center md:justify-left items-center p-10 relative">
        <div data-aos="zoom-in" data-aos-duration="1000" className='flex justify-center md:justify-start w-full'>
          <BoxType1
            title={t(`successSection.boxTypes.radiography.title`)}
            imgSrc={'../assets/icons/radiograpphy-icon.png'}
            description={t(`successSection.boxTypes.radiography.description`)}
          />
        </div>
        <div data-aos="zoom-in" data-aos-duration="1000" className='flex justify-center md:justify-end w-full'>
          <BoxType2
            title={t(`successSection.boxTypes.discovery.title`)}
            imgSrc={'../assets/icons/discovery-icon.png'}
            description={t(`successSection.boxTypes.discovery.description`)}
          />
        </div>
        <div data-aos="zoom-in" data-aos-duration="1000" className='flex justify-center md:justify-start w-full'>
          <BoxType1
            title={t(`successSection.boxTypes.headway.title`)}
            imgSrc={'../assets/icons/headway-icon.png'}
            description={t(`successSection.boxTypes.headway.description`)}
          />
        </div>
        <div data-aos="zoom-in" data-aos-duration="1000" className='flex justify-center md:justify-end w-full'>
          <BoxType2
            title={t(`successSection.boxTypes.goal.title`)}
            imgSrc={'../assets/icons/goal-icon.png'}
            description={t(`successSection.boxTypes.goal.description`)}
          />
        </div>
      </div>
    </section>
      <section>
      <div className="bg-slate-700 w-full flex justify-center items-center">
            <div className="glassContainer flex justify-center text-center w-full p-2 md:p-10 m-5 md:m-16">
             <h3 className='text-center text-lg lg:text-2xl uppercase'><b className='text-2xl md:text-[3rem]'>"</b>{t('bioSection.quote')}<b className='text-2xl md:text-[3rem]'>"</b></h3>
            </div>
        </div>
      </section>
      <section id="bio" className='flex flex-wrap overflow-hidden zoom-blur-bg h-auto md:h-[1000px]'>
      <div className="content-container flex justify-center items-center w-full md:w-7/12 h-full">
        <div className="glassContainer flex flex-col text-justify md:text-left gap-5 m-2 md:m-10 p-5">
          <h3 className='uppercase text-3xl'>{t('bioSection.title')}</h3>
          <h4 className='text-2xl'>{t('bioSection.subtitle')}</h4>
          <p className='text-base'>{t('bioSection.paragraph')}</p>
          <ul className='list-disc px-2 md:px-5 text-base'>
            {t('bioSection.bulletPoints', { returnObjects: true }).map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
          <div className="flex flex-col justify-end items-end">
            <h4 className='text-left uppercase text-2xl'>MERCEDES Jahn</h4>
            <h6>Mentora de Plenitud Corporativa</h6>
            <div className="divider"></div>
            <div className="social-contianer flex justify-center gap-5">
              <FacebookIcon />
              <XIcon />
              <GoogleIcon />
              <InstagramIcon />
              <LinkedInIcon />
            </div>
          </div>
        </div>
      </div>
      <div className="img-container flex justify-center items-center relative w-full md:w-5/12 h-[650px] md:h-[1000px]">
        <div data-aos="zoom-in-left" data-aos-duration="1000" className="image-container w-[320px] md:w-[450px] h-[500px] md:h-[650px] rounded-xl overflow-hidden border-8 border-[#C00000]">
          <img className="object-cover w-full h-full " src={require("../assets/images/JH_PR.jpeg")} alt="" />
        </div>
        <div className='hidden flex justify-center items-center absolute top-[470px] md:top-[870px] bg-black bg-opacity-80 p-5'>
          <h3 className='text-white text-xl lg:text-2xl uppercase'>{t('translation.bioSection.quote')}</h3>
        </div>
      </div>
    </section>
      <section id='reviews' className='md:py-10 px-10'>
        <div className="title py-10">
          <h2 className='uppercase'>{t('customerWords')}</h2>
        </div>
        <ReviewsSliders/>
      </section>
      <section id='contacto'>
        <WavesForm/>
      </section>
    <footer>
      <Footer/>
    </footer>
  </>

  )
}

export default FrontPage