import React from 'react'
import './ReviewCardsStyle.css'



const ReviewCards = ({image,review,name,company,color,height}) => {
  return (<>
    <div className='review-card relative flex flex-col justify-center items-center w-[290px] md:w-[330px]'>
        <div className='Avatar relative top-[32px] bg-white flex justify-center items-center rounded-full w-24 h-24 overflow-hidden border-2 border-black'>
            <img  className={`object-cover w-auto ${height}`} src={image} alt={company} />
        </div>
        <div className={`text-containert text-center gap-3 flex flex-col px-5 py-10 rounded-xl border-2 card-box-shadow-${color} h-[650px] md:h-[550px]`}>
            <p className='py-5 text-justify'>{review}</p>
            <h6 className='User text-xl font-bold'>{name}</h6>
            <p className='px-[25%] text-sm text-slate-600'>{company}</p>
        </div>

    </div>
  </>
  )
}

export default ReviewCards 