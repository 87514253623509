import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { responseData } from './ModelData';
import { getAllQuestionnaireResponses } from '../../services/questionarrieResponse';

export default function ResultTable() {
  const [responses, setResponses] = useState([]);

  useEffect(()=>{
    getAllQuestionnaireResponses().then(response => {
      // Extract the data from the response
      const data = response.data; 
      // Now you can use the data
      setResponses(data);
    });
  },[])

  const handleRemove = (responseId) => {
    // Implement your logic to remove the response
    console.log(`Removing response with ID ${responseId}`);
  };

  const handleViewDetails = (responseId) => {
    // Construct the URL for the cuestionario/:id route
    const url = `/cuestionario/${responseId}`;
    
    // Open the URL in a new tab
    window.open(url, '_blank');
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>ID de Respuesta</TableCell>
          <TableCell>ID de Usuario</TableCell>
          <TableCell>Retención de Ejecutivos</TableCell>
          <TableCell>Manifestaciones Observadas</TableCell>
          <TableCell>Contexto de Ocurrencia</TableCell>
          <TableCell>Problemas de la Empresa</TableCell>
          <TableCell>Áreas de la Empresa</TableCell>
          <TableCell>Comentarios Adicionales</TableCell>
          <TableCell>Creado en</TableCell>
          <TableCell>Acciones</TableCell>
                    </TableRow>
        </TableHead>
        <TableBody>
          {responses.map((row) => (
            <TableRow key={row.response_id}>
              <TableCell>{row.response_id}</TableCell>
              <TableCell>{row.user_id}</TableCell>
              <TableCell>{row.executives_retention}</TableCell>
              <TableCell>{row.observed_manifestations}</TableCell>
              <TableCell>{row.occurrence_context}</TableCell>
              <TableCell>{row.company_issues}</TableCell>
              <TableCell>{row.areas_of_company}</TableCell>
              <TableCell>{row.additional_comments}</TableCell>
              <TableCell>{row.created_at}</TableCell>
              <TableCell>
                <Button variant="contained" onClick={() => handleViewDetails(row.response_id)}>View Details</Button>
                <Button variant="contained" onClick={() => handleRemove(row.response_id)}>Remove</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
