const translations = {
    en: {
      waves: {
        title: "Be the key piece that drives your company's talent",
        description: "360 Executive Coaching (Mindset, Evolution & Execution) for CEOs, directors, and top talents who need to perform successfully for the benefit of large organizations without losing personal and professional balance.",
        startHere: 'Start Here'
      },
      frontPage: {
        welcome: 'Welcome to Our Company',
        description: 'We provide top-notch solutions for your business needs. Our team of experts is dedicated to helping you achieve success.',
        contactUs: 'Contact Us',
        wavesTitle: 'Trigger and leverage the strengths of human capital',
        wavesParagraph1: 'As {{leaderRole}}, being the hero or villain depends on your skills to ensure that {{CEOs}} accelerate the company\'s objectives and position it at the top of the market.',
        wavesParagraph2: 'In {{JahnInstitute}} we understand the fundamental role you play in achieving this; therefore, we facilitate your work by offering {{Coaching}}, as well as effective {{DevelopmentStrategies}} designed for these key players to learn to take control of their emotions, decisions, and actions.',
        leaderRole: 'leader of the Human Resources area',
        ecoCoaching: 'Corporate Coaching',
        developmentStrategies: 'Development and Work-Life Wellbeing Strategies',
        buttonSolution:'Let´s discuss your needs',
        coachingROI: "Certified Executive Coaching ROI",
        returnOnInvestment: "Return on Investment against competition",
        investmentRecovery: "Investment Recovery",
        productivity: "Productivity",
        workLifeBalance: "Work-Life Balance",
        solutionsTitle: "Life-changing solutions in less than six months",
        solutionsSubtitle: "1:1 Mentoring/Coaching | Team Coaching | Advanced Certifications",
        successSection: {
            title: 'Keys that determine your success',
            methodologyDescription: 'Our Progressive Success 360 methodology has delivered successful results, proven by dozens of executives and their corporations in multiple countries.',
            boxTypes: {
              radiography: {
                title: 'Radiography',
                description: 'The phase of <b>customized analysis</b> to understand what your talent and organization want and need.'
              },
              discovery: {
                title: 'Discovery',
                description: 'The <b>moment of discovery</b> of professional/corporate requirements, the specific solution, and the vigilant sponsor of the advancement process.'
              },
              headway: {
                title: 'Headway',
                description: 'The <b>work stage</b> through live online sessions (60-90 mins) to unlock goals and see <b>tangible progress in less than six months</b>.'
              },
              goal: {
                title: 'Goal',
                description: 'The <b>program closure</b> to verify the goals achieved in the presence of the collaborator, sponsor, and HR representative.'
              }
            }
        },
        growSection: {
        title: 'Everyone grows, you grow',
        description: `Feeling overwhelmed by all the existing Executive Coaching options to drive your organization's top talent according to their goals is normal.
            At Jahn Institute, we help you make decisions confidently so that you can be sure to select the right solution, according to the situation and timing, future outcomes, and expected ROI.`
        },
        bioSection: {
            title: 'Mercedes Jahn, Corporate Fulfillment Mentor',
            subtitle: 'Helping people transform to achieve success with fulfillment is my passion.',
            paragraph: `30 years ago, I had the fortune to arrive in Mexico to introduce Coaching. I belong to the top 2% of Senior Master Coaches worldwide and through Jahn Institute, in partnership with WWB, LLC, I offer specialized solutions with the support of my team of expert consultants to strengthen large organizations.`,
            bulletPoints: [
              'Senior Comprehensive Executive Coach Level C, consultant, mentor, and biohacker',
              'Board member, writer, speaker, and researcher',
              'Specialist in cognitive neuroscience with degrees in International Business and Languages (translation specialization)',
              'Master’s in Emotional-Cognitive-Behavioral Psychotherapy and Business Management',
              'Ph.D. in Psychology'
            ],
            quote: `"It's not what I do, but what people achieve when they work with me."`
        },
        customerWords: 'In the words of our clients'
        
      },
      navbar: {
        home: 'Home',
        solutions: 'Solutions',
        contact: 'Contact'
      },
      options: {
        menuTitle: 'Master Executive Coaching',
        branding: {
          title: "Branding and Leadership for CEOs and Top Execs.",
          description: "Nothing is more captivating in business than a naturally trained personality, both for making cold-headed decisions and for leading with certainty in any situation."
        },
        biohacking: {
          title: "Biohacking",
          description: "By integrating simple wellness techniques and habit changes into daily life, individuals increase their productivity by almost 50%, stay healthy, with high levels of energy and greater resilience to stress."
        },
        xplendor: {
          title: "Xplendor",
          description: "Those who know their purpose, are passionate about their work and hone their strengths radiate happiness that is reflected in their work performance. Our job is to help find fulfillment."
        },
        mindHacking: {
          title: "Mind hacking",
          description: "Making things happen has its secrets: learning to plan, manage emotions, communicate effectively and decide without hesitation. The strategy? Mastering the mind."
        },
        teamCoaching: {
          title: "Team Coaching",
          description: "High performance goes hand in hand with healthy work environments, reason enough to promote conflict-free relationships and spaces open to the expression of ideas."
        },
        certifications: {
          title: "Certifications 'Leader Coach' and 'Mentor Coach'",
          description: "Are you looking for the leaders of your organization to guide and inspire their teams with certainty? With the right tools, impressive results are obtained: greater self-confidence, independence and proactivity, essential qualities for improving performance for everyone."
        },
     },
     optionsGroup: {
        boostProductivity: {
            title: "Boost Productivity",
            description: "Offer individual and group solutions that quickly resolve obstacles reducing human capital productivity. The result? 63% less turnover, 70% increased efficiency.",
        },
        promoteTrust: {
            title: 'Promote Trust',
            description: 'Restore self-control to employees, instill confidence in clients, and enhance your competitive standing.',
        },
        exceedSalesGoals: {
            title: 'Exceed Sales Goals',
            description: 'Immediate impact on company revenue thanks to the transformation of standout personnel.',
        },
        buttons: {
          learnMore: 'I Want to Know More',
          needMentorship: 'Do We Need Mentorship?'
        }
    },
    reviews: {
        review1: {
          user: 'Filip Tauson',
          company: 'CEO Dow Company, Mexico and Latam',
          review: 'Direction, purpose and cultural immersion are the key and skills I learn from Jahn Institute. Mercedes Jahn is the perfect starting point of any senior leader who wants to make a quick impact on people and their contribution to the organization as a foreign executive in an overseas work environment.'
        },
        review2: {
          user: 'Alejandro Salum',
          company: 'CEO Tascomm Mexico',
          review: 'I have had the pleasure of working with Dr. Jahn on several projects. She delivers tangible results in the short term. She has been my personal and professional coach for many years, and I have worked with her from the most complex aspects of my professional performance to the most significant in my personal life.'
        },
        review3: {
          user: 'Hernán N.',
          company: 'Commercial Director Arca Continental Peru',
          review: 'I am very satisfied with Mercedes Jahn\'s coaching methodology. Open, empathetic and with great listening skills, our conversations always left me with an important reflection that led me to self-discovery, to have a clear purpose, and to return with strength to the spotlight to take our sales to the top.'
        }
    },
    wavesForm: {
        headerTitle: 'Make your organization feel in good hands',
        headerSubtitle: 'At <b>Jahn Institute</b>, we are ready to help you visualize your authority. It’s time to <b>transform people into committed, resilient leaders with less desire to leave, thereby strengthening your company.</b>',
        nameLabel: 'Name',
        emailLabel: 'Email',
        messageLabel: 'Message',
        defaultMessage: 'Message',
        contactButton: 'Contact'
      }
    },
    es: {
      waves: {
        title: 'Sé la pieza clave que impulsa el talento de tu empresa',
        description: 'Coaching Ejecutivo 360 (Mindset, Evolution & Execution) para CEO´s, directores y top talents que necesitan tener un desempeño exitoso para beneficio de grandes organizaciones, sin perder el balance personal y profesional.',
        startHere: 'Empieza Aqui'
      },
      frontPage: {
        welcome: 'Bienvenido a Nuestra Empresa',
        description: 'Proporcionamos soluciones de primer nivel para las necesidades de su negocio. Nuestro equipo de expertos está dedicado a ayudarlo a lograr el éxito.',
        contactUs: 'Contáctenos',
        wavesTitle: 'Detona y aprovecha las fortalezas del capital humano',
        wavesParagraph1: 'Como {{leaderRole}}, ser el bueno o el malo de la película depende de tus competencias para garantizar que los {{CEOs}} aceleren los objetivos de la compañía y la posicionen en primer lugar del mercado.',
        wavesParagraph2: 'En {{JahnInstitute}} entendemos lo fundamental de tu función para conseguirlo; por eso, facilitamos tu labor ofreciendo {{Coaching}}, al igual que efectivas {{DevelopmentStrategies}} diseñadas para que estos actores clave aprendan a tomar control de sus emociones, decisiones y acciones.',
        leaderRole: 'líder del área de Recursos Humanos',
        ecoCoaching: 'Coaching Corporativo',
        developmentStrategies: 'Estrategias de Desarrollo y Bienestar vida-trabajo',
        buttonSolution:'Necesito sus soluciones',
        coachingROI: "El ROI del Coaching Ejecutivo certificado",
        returnOnInvestment: "Rentabilidad frente a la competencia",
        investmentRecovery: "Recuperación de la inversión",
        productivity: "Productividad",
        workLifeBalance: "Equilibrio vida/trabajo",
        solutionsTitle: "Soluciones que transforman vidas en menos de seis meses",
        solutionsSubtitle: "Mentoría/Coaching 1:1 | Coaching de equipo | Certificaciones avanzadas",
        successSection: {
            title: 'Claves que determinan tu éxito',
            methodologyDescription: 'Nuestra metodología Progressive Success 360 ha brindado resultados exitosos, comprobados por decenas de ejecutivas y ejecutivos, así como por sus corporaciones en múltiples países.',
            boxTypes: {
              radiography: {
                title: 'Radiografía',
                description: 'La fase de <b>análisis personalizado</b> para entender qué quiere y qué necesita tu talento y la organización.'
              },
              discovery: {
                title: 'Descubrimiento',
                description: 'El <b>momento de hallazgo</b> de los requerimientos profesionales/corporativos, la solución específica y el sponsor vigilante del proceso de avance.'
              },
              headway: {
                title: 'Progreso',
                description: 'La <b>etapa de trabajo</b> mediante sesiones online en vivo (60-90 mins) para desbloquear objetivos y ver <b>progreso evidente en menos de seis meses</b>.'
              },
              goal: {
                title: 'Meta',
                description: 'El <b>cierre del programa</b> para comprobar las metas alcanzadas en presencia del colaborador, sponsor y representante de Recursos Humanos.'
              }
            }
        },
        growSection: {
            title: 'Crecen todos, creces tú',
            description: `Sentir agobio frente a todas las opciones de Coaching Ejecutivo existentes para impulsar al top talent de tu organización, según sus objetivos, es normal.
              En Jahn Institute te ayudamos a tomar decisiones con seguridad para que tengas la certeza de seleccionar la solución adecuada, de acuerdo con la situación y el momento, los resultados futuros y el ROI esperado.`
        },
        bioSection: {
            title: 'Mercedes Jahn, Mentora de Plenitud Corporativa',
            subtitle: 'Ayudar a la gente a transformarse para alcanzar el éxito con plenitud es mi pasión.',
            paragraph: `Hace 30 años tuve la fortuna de llegar a México para dar a conocer el Coaching. Pertenezco al 2% de los Senior Master Coaches a nivel mundial y a través de Jahn Institute, en sociedad con WWB, LLC, ofrezco soluciones especializadas con el apoyo de mi equipo de consultores expertos para fortalecer a grandes organizaciones.`,
            bulletPoints: [
              'Coach Ejecutivo Integral Senior Nivel C, consultora, mentora y biohacker',
              'Miembro de consejo, escritora, conferencista e investigadora',
              'Especialista en neurociencias cognitivas con licenciaturas en Negocios Internacionales y en Idiomas (especialidad traducción)',
              'Maestra en Psicoterapia Emocional-Cognitivo-Conductual y en Gestión de Empresas',
              'Doctora en Psicología'
            ],
            quote: `"No es lo que hago, sino lo que las personas logran cuando trabajan conmigo."`
        },
        customerWords: 'En palabras de nuestros clientes',
      },
      navbar: {
        home: 'Inicio',
        solutions: 'Soluciones',
        contact: 'Contacto'
      },
      options: {
        menuTitle: 'Master Coaching Ejecutivo individual',
        branding: {
          title: "Branding y liderazgo para CEO´s y Top Execs.",
          description: "Nada más cautivador en los negocios que una personalidad natural bien entrenada, tanto para tomar decisiones con la cabeza fría como para liderar con certeza en cualquier situación."
        },
        biohacking: {
          title: "Biohacking",
          description: "Al integrar a la vida diaria sencillas técnicas wellness y cambio de hábitos, las personas elevan su productividad casi 50%, se mantienen saludables, con altos niveles de energía y mayor resiliencia ante el estrés."
        },
        xplendor: {
          title: "Xplendor",
          description: "Quien conoce su propósito, le apasiona su trabajo y pule sus fortalezas irradia felicidad que se refleja en su desempeño laboral. Nuestro trabajo es ayudar a encontrar la plenitud."
        },
        mindHacking: {
          title: "Mind hacking",
          description: "Hacer que las cosas sucedan tiene sus secretos: aprender a planear, manejar emociones, comunicar con efectividad y decidir sin titubear. ¿La estrategia? Dominar la mente."
        },
        teamCoaching: {
          title: "Coaching de equipo",
          description: "El alto desempeño va de la mano con los ambientes laborales sanos, razón suficiente para propiciar relaciones libres de pugnas y espacios abiertos a la expresión de ideas."
        },
        certifications: {
          title: "Certificaciones 'Leader Coach' y 'Mentor Coach'",
          description: "¿Buscas que los líderes de tu organización orienten e inspiren con certeza a sus equipos? Con las herramientas precisas se obtienen resultados impresionantes: mayor autoconfianza, independencia y proactividad, cualidades indispensables para mejorar el desempeño de todos."
        },
     },
     optionsGroup: {
        boostProductivity: {
          title: "Eleva la productividad",
          description: "Dispón de soluciones individuales y grupales que resuelven en corto tiempo los obstáculos que disminuyen la productividad de tu capital humano. ¿El resultado? 63% menos rotación, 70% más eficiencia.",
        },
        promoteTrust: {
          title: 'Promueve la confianza',
          description: 'Devuelve el autocontrol a los colaboradores, transmite seguridad a los clientes y mejora tu posicionamiento frente a la competencia.',
        },
        exceedSalesGoals: {
          title: 'Supera metas de venta',
          description: 'El impacto directo en la facturación de las empresas se refleja de inmediato, gracias a la transformación del personal destacado.',
        },
        buttons: {
            learnMore: 'Quiero Saber Más',
            needMentorship: '¿Necesitamos Mentoría?'
        }
     },
     reviews: {
        review1: {
          user: 'Filip Tauson',
          company: 'CEO Dow Company, México y Latam',
          review: 'Dirección, propósito e inmersión cultural son las claves y habilidades que aprendí en Jahn Institute. Mercedes Jahn es el punto de partida perfecto para cualquier líder senior que quiera tener un impacto rápido en las personas y su contribución a la organización como ejecutivo extranjero en un entorno laboral en el extranjero.'
        },
        review2: {
          user: 'Alejandro Salum',
          company: 'CEO Tascomm México',
          review: 'He tenido el placer de trabajar con la Dra. Jahn en varios proyectos. Entrega resultados tangibles a corto plazo. Ha sido mi coach personal y profesional por muchos años y he trabajado con ella desde los aspectos más complejos de mi desempeño profesional, hasta los más significativos de mi vida personal.'
        },
        review3: {
          user: 'Hernán N.',
          company: 'Director Comercial Arca Continental Perú',
          review: 'Estoy muy satisfecho con la metodología de coaching de Mercedes Jahn. Abierta, empática y con gran capacidad de escucha, nuestras conversaciones siempre me dejaron una reflexión importante que me llevó al autodescubrimiento, a tener claro mi propósito y a regresar con fuerza al spotlight para llevar nuestras ventas a tope.'
        }
    },
    wavesForm: {
        headerTitle: 'Logra que tu organización se sienta en buenas manos',
        headerSubtitle: 'En <b>Jahn Institute</b> estamos listos para ayudarte a visibilizar tu autoridad. Es momento de <b>transformar a las personas en líderes comprometidos, resilientes y con menos deseos de irse para fortalecer a tu empresa.</b>',
        nameLabel: 'Nombre',
        emailLabel: 'Correo',
        messageLabel: 'Mensaje',
        defaultMessage: 'Mensaje',
        contactButton: 'Contactar'
      },
    }
  };
  
  export default translations;
  