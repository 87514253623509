import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from './Translations/translations';

// Define the languages and fallback language
const languages = ['en', 'es'];

// Initialize i18next
i18next
  .use(initReactI18next)
  .init({
    resources: translations,
    lng: 'es', // default language
    fallbackLng: 'es',
    whitelist: languages, // allow only these languages
    interpolation: {
      escapeValue: false, // not needed for React as it escapes by default
    },
    react: {
      useSuspense: false, // true if you're using suspense
    },
  })
  .then(() => {
    console.log('i18next initialized successfully');
  })
  .catch((error) => {
    console.error('Failed to initialize i18next', error);
  });

export default i18next;
