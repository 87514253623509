import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Link, useNavigate, useMatch } from "react-router-dom";
import { getQuestionnaireResponseById } from '../../services/questionarrieResponse';

const QuestionnaireResult = ({ questionnaireResponse }) => {

  const match= useMatch("/:firstRoute/:secondRoute/*");
  const { firstRoute,secondRoute } = match.params;
  const [result,setResult] = useState();

  useEffect(() =>{
    getQuestionnaireResponseById(secondRoute).then(response => {
      // Extract the data from the response
      const data = response; 
      setResult(data);
    });
  },[])

  const questions = {
    response_id:"ID Cuestionario",
    user_id: "Usuario",
    executives_retention: "¿Tienes ejecutivos de alto nivel que quieres retener?",
    observed_manifestations: "¿Notas que está sucediendo alguna o varias de estas manifestaciones? Marca todas las que apliquen",
    occurrence_context: "Está sucediendo con:",
    company_issues: "¿Está pasando esto en tu empresa?",
    areas_of_company: "¿Donde?",
    additional_comments: "¿Algo más que quieres agregar?",
    created_at:"Fecha de Creacion",
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    const formattedDate = date.toLocaleString('es-MX', options);
    return formattedDate;
  };


  return (
    <Container maxWidth="md">
      <Typography variant="h3 ">Questionnaire Result</Typography>
      <div>
        {result && Object.entries(result).map(([key, value]) => (
          <div key={key}>
            <Typography variant="body1">
              <strong>{questions[key]}:</strong> {Array.isArray(value) ? (
                value.map((item, index) => (
                  <li key={index}>{item}</li>
                ))
              ) : key === 'created_at' ? (
                formatDate(value)
              ) : (
                value
              )}
            </Typography>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default QuestionnaireResult;
