import React from 'react';
import './wavesStyle.css';
import WaveIcon from './WaveIcon';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Waves = () => {
  const { t } = useTranslation('waves');

  return (
    <>
      <div className="header">
        <div className="inner-header flex flex-wrap justify-around items-center w-full mb-48 md:mb-0">
          <div className="text-container flex flex-col justify-center md:justify-start gap-5 w-full md:w-1/2 p-5">
            <h1 className='text-left font-bold uppercase'>{t('title')}</h1>
            <h6 className='text-justify'>{t('description')}</h6>
            <div className="w-64 px-3">
              <Link to={"/questionnaire"}>
                <button className='button-24'>{t('startHere')}</button>
              </Link>
            </div>
          </div>
          <div className="image-container flex flex-col justify-center items-center w-[90%] md:w-1/2 p-5">
            <div className="rounded-full overflow-hidden bg-[#C00100] w-full h-[200px] md:h-[350px]">
              <img className='mt-[5%]' src={require("../../assets/images/JH-01.png")} alt="Image"/>
            </div>
          </div>
        </div>
        <div>
          <WaveIcon />
        </div>
      </div>
    </>
  );
}

export default Waves;
