import React,{useState} from 'react'
import { Options_Group } from './Data';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button } from '@mui/material';

const SelectBoxType2 = () => {
    const [currentOption, setCurrentOption] = useState(Options_Group[0]);
const [currentIndex, setCurrentIndex] = useState(0);

const handleAdd = () => {
    if (Options_Group.length > currentIndex + 1) {
        setCurrentIndex(prevIndex => prevIndex + 1); // Use functional update to ensure correct state update
        setCurrentOption(Options_Group[currentIndex + 1]);
    } else {
        setCurrentIndex(0);
        setCurrentOption(Options_Group[0]);
    }
}

const handleSubtract = () => {
    if (currentIndex > 0) {
        setCurrentIndex(prevIndex => prevIndex - 1); // Use functional update to ensure correct state update
        setCurrentOption(Options_Group[currentIndex - 1]);
    } else {
        setCurrentIndex(Options_Group.length - 1);
        setCurrentOption(Options_Group[Options_Group.length - 1]);
    }
}

const navigate = useNavigate();

const handleTestClick = () => {
  navigate('/questionnaire');
};

  return (
    <div className="flex flex-wrap">
        <div className="flex flex-col gap-3 w-full lg:w-7/12 text-left">
            <h5 className='font-bold text-3xl'>{currentOption.title}</h5>
            <p>{currentOption.description}</p>
            <div className="hidden lg:block w-120 py-10 flex gap-5">
                <button className='button-23'>Quieres Saber Más</button>
                <Button variant="text"onClick={handleTestClick}>¿Necesitamos Mentoría?</Button>
            </div>
        </div>
        <div className='w-full lg:w-5/12 flex flex-col justify-end items-end'>
            <div className="image-container w-[500px] h-[300px]">
                <img className='object-cover w-full h-full' src={currentOption.imgUrl} alt="" />
            </div>
            <div className="arrowContainer flex">
                <div className='flex justify-center items-center bg-slate-700 p-5 text-white w-16 h-12 cursor-pointer' onClick={() =>handleSubtract()}>
                    <ArrowBackIosIcon/>
                </div>
                <div className='flex justify-center items-center bg-slate-700 p-5 text-white w-16 h-12 cursor-pointer' onClick={() =>handleAdd()}>
                    <ArrowForwardIosIcon/>
                </div>
            </div>
        </div>
        <div className="flex justify-center items-center lg:hidden w-full py-10  gap-5">
                <button className='button-23'>Quiero Saber Más</button>
                <Button variant="text"onClick={handleTestClick}>Haz tu test</Button>
        </div>
    </div>
  )
}

export default SelectBoxType2