import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { Stepper, Step, StepLabel, Button, Typography, Box, Container } from '@mui/material';
import UserInfoForm from './UserInfoForm';
import ContentForm from './ContentForm';
import CompleteForm from './CompleteForm';



export default function Questionnaire() {
  const [activeStep, setActiveStep] = useState(0);
  const [user,setUser] = useState();
  const [questionsResponse,setQuestionsResponse] = useState();
  const navigate = useNavigate();

  const steps = ['Paso 1', 'Paso 2', 'Paso 3']; // Define your steps here

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <UserInfoForm user={user} setUser={setUser}/>;
    case 1:
      return <ContentForm questionsResponse={questionsResponse} setQuestionsResponse={setQuestionsResponse}/>;
    case 2:
      return <CompleteForm/>;
    default:
      return 'Unknown stepIndex';
  }
}

  const handleNext = () => {
    if(!user && activeStep==0){
      alert("Falta Agregar datos")
      return;
    }
    console.log(questionsResponse);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    // Handle completion action, such as redirecting to the home page
    console.log('Questionnaire completed!');
    // Redirect to home page using React Router
    navigate('/'); // Redirect to the home page route
  };

  return (
    <Container maxWidth="md">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ my: 4 }}>
        <Typography variant="h6">{getStepContent(activeStep)}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          {activeStep === steps.length - 1 ? 
          null:  
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Regresar
          </Button>}
        
          <Button
                variant="contained"
                color={activeStep === steps.length - 1 ? 'success' : 'primary'}
                onClick={activeStep === steps.length - 1 ? handleComplete : handleNext}
              >
                {activeStep === steps.length - 1 ? 'Completado' : 'Siguiente'}
              </Button>
        </Box>
      </Box>
    </Container>
  );
}
